<template>
    <v-container fluid>
        <v-toolbar elevation="1" dark color="primary">
            <v-toolbar-title>
                Eventos adversos
            </v-toolbar-title>
            <v-spacer/>
            <v-btn
                icon
                dark
                @click="carga"
            >
                <v-icon>mdi-sync</v-icon>
            </v-btn>
        </v-toolbar>

        <v-data-table
            elevation="2"
            :headers="headers"
            :items="items"
            disable-sort
            :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            item-key="id"
            :loading="loading"

            :server-items-length='total'
            :options.sync="optionsTable"
        >


            <template v-slot:no-data>
                <span>No se encontraron resultados...</span>
            </template>

            <template v-slot:[`item.paciente`]="{ item }">
                ({{item.paciente}}) {{item.pacienteNombre}}<br/>
                {{item.pacienteFechaNacimiento}} ({{item.pacienteEdad}})<br/>
                C.U.R.P.:{{item.pacienteCurp}}
            </template>
            <template v-slot:[`item.estado`]="{ item }">
                <v-chip small :color="colorEstado(item.estado)" class="white--text">
                    {{item.estadoNombre}}
                </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn text @click="muestraEvento(item.folioInterno)">
                    <v-icon>mdi-magnify</v-icon>
                </v-btn>
            </template>
        </v-data-table>

    </v-container>
</template>

<script>
export default {
    data:()=>({
        items:[],
        //detalles:[],
        //totalDetalles:0,
        optionsTable:{},
        total:0,
        params:{},
        headers:[
            {text:'Folio', value:'folioInterno', width:"12%"},
            {text:'Paciente', value:'paciente', width:"30%"},
            {text:'Descripción', value:'descripcionBreve'},
            {text:'Fecha', value:'fecha', width:"15%"},
            {text:'Estado', value:'estado', width:"10%"},
            {text:'', value:'actions', width:"5%"},
        ],
        loading:false,

    }),
    methods:{
        async carga(){
            this.loading = true
            let params = {...this.params}
            params.start = (this.optionsTable.page-1 )*this.optionsTable.itemsPerPage,
            params.limit = this.optionsTable.itemsPerPage

            try{
                let req = await this.$http({
                    method:'GET',
                    url: '/calidad/listAdversos',
                    params:params
                })
                this.loading=false
                this.items = req.data.root
                this.total = req.data.total
            }catch(err){
                this.loading=false
                this.$store.dispatch('security/revisaError',err)
            }
            //console.log('cargando')
        },
        
        colorEstado(itm){
            let color = 'black'
            switch (itm){
                case 1:
                    color="blue"
                    break
                case 2:
                    color="orange"
                    break
                case 3:
                    color="green"
                    break
                case 99:
                    color="red"
                    break
            }
            return color
        },
        muestraEvento(id){
            this.$router.push({ path: '/calidad/evento/'+id })
        },
    },
    watch:{
        optionsTable: {
            handler () {
                this.carga()
            },
            deep: true,
        },
    },

}
</script>

<style>

</style>